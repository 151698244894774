import React from 'react';
import type { FC } from 'react';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import type { ReportPageContext } from '../../gatsby-node';
import type { ReportTemplateQuery } from '../../types/graphql-types';

interface PageProps {
  data: ReportTemplateQuery;
  pageContext: ReportPageContext;
}

const Page: FC<PageProps> = ({ data }) => (
  <Layout>
    <SEO
      title={data.markdownRemark?.frontmatter?.title ?? ''}
      description={data.markdownRemark?.frontmatter?.description ?? ''}
      keywords={data.markdownRemark?.frontmatter?.keywords ?? ''}
    />
    <article css={caseReportBaseStyle}>
      <h1>{data.markdownRemark?.frontmatter?.title ?? '無題'}</h1>
      <address>動物がんクリニック東京&nbsp;&nbsp;池田雄太</address>
      <div
        dangerouslySetInnerHTML={{ __html: data.markdownRemark?.html ?? '' }}
      />
    </article>
  </Layout>
);
export default Page;

const caseReportBaseStyle = css`
  font-size: 1.3rem;
  line-height: 2rem;
  width: 40em;
  margin: 2rem auto 0;

  @media (max-width: 560px) {
    width: 90%;
    padding: 0 1rem;
  }

  address {
    text-align: right;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  address {
    margin-bottom: 1.3rem;
  }

  p {
    /* Markdownの改行をそのまま反映する。プラグインもあるがまずは簡易的に。 */
    white-space: pre-wrap;
  }

  figcaption {
    text-align: center;
    font-size: 1rem;
  }
`;

export const query = graphql`
  query ReportTemplate($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        keywords
      }
    }
  }
`;
